<template>
  <v-card class="pa-6">
    <v-row>
      <v-col cols="12">
        <h2
          style="
            font-family: 'IBM Plex Sans Thai';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 40px;
          "
        >
          สรุปการแจ้งเบาะแสยาเสพติด
        </h2>
        <h2 style="color: #000000">ข้อมูลตามประเภทยาเสพติด</h2>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col cols="12" md="12" sm="12"
        ><v-card
          ><p style="padding: 20px">สรุปข้อมูลแยกตามเดือน</p>
          <div id="chart">
            <apexchart
              type="bart"
              height="300"
              :options="chartOptions"
              :series="seriesUsers"
            ></apexchart></div></v-card
      ></v-col>
    </v-row> -->
    <v-row>
      <v-col cols="12" sm="4" md="4">
        <v-autocomplete
          hide-details
          v-model="m_group"
          :items="m_groupItems"
          item-text="m_group"
          placeholder="เลือก ปส."
          outlined
          dense
          @change="getm_area(m_group)"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-autocomplete
          hide-details
          v-model="m_area"
          :items="m_areaItems"
          item-text="m_area"
          placeholder="เลือก ภาค"
          outlined
          dense
          @change="getm_province(m_area)"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-autocomplete
          hide-details
          v-model="m_province"
          :items="m_provinceItems"
          item-text="m_province"
          placeholder="เลือก จังหวัด"
          outlined
          dense
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-select
          v-model="drug_name"
          :items="itemdrug"
          class="custom-label-color"
          placeholder="ยาเสพติด"
          outlined
          dense
        />
      </v-col>
      <!-- </v-row>
    <v-row> -->
      <v-col cols="12" sm="4" md="4">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              hide-details
              v-model="date"
              append-icon="mdi-calendar"
              placeholder="วันที่เริ่มต้น"
              outlined
              dense="auto"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" @input="menu = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              hide-details
              v-model="date1"
              append-icon="mdi-calendar"
              placeholder="ถึงวันที่"
              outlined
              dense="auto"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date1" @input="menu1 = false"></v-date-picker>
        </v-menu>
      </v-col>
      <v-row class="mt-0 mr-1" justify="end">
        <v-col cols="12" sm="4" md="4" class="text-right">
          <v-btn @click="clear()">เคลียร์ </v-btn>
        </v-col>
        <v-col cols="12" sm="4" md="4" class="text-right">
          <v-btn dark color="#000000" @click="getAllData()">ค้นหา </v-btn>
        </v-col>
      </v-row>
    </v-row>
    <v-row class="d-flex justify-space-between">
      <v-col cols="12" sm="4" md="4">
        <v-text-field
          hide-details
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          outlined
          dense
        />
      </v-col>
      <v-col class="text-right" cols="12" sm="4" md="4">
        <ExportTable4 :data="items" :formDate="date" :toDate="date1" />
      </v-col>
    </v-row>

    <v-data-table
      class="mt-3 elevation-1"
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-row justify="center">
          <!-- <v-icon @click="ViewBanner(item)">mdi-content-copy</v-icon> -->
          <v-icon @click="UpdateBanner(item)" class="mx-2"
            >mdi-text-box-search-outline</v-icon
          >
          <!-- <v-icon @click="DeleteBanner(item)">mdi-delete</v-icon> -->
        </v-row>
      </template></v-data-table
    >
  </v-card>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
import ExportTable4 from "@/views/ExportExcel/ExportTable4.vue";
export default {
  components: {
    Loading,
    ExportTable4,
  },

  data: () => ({
    m_group: "",
    m_groupItems: [],
    m_area: "",
    m_areaItems: [],
    m_province: "",
    m_provinceItems: [],
    search: "",
    menu: false,
    menu1: false,
    date: new Date("2022-01-01").toISOString().substr(0, 10),
    date1: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    headers: [
      { text: "ลำดับ", value: "number", align: "start" },
      { text: "ยาเสพติด", value: "drug_name", align: "start" },
      { text: "จำนวน(รายการ)", value: "count", align: "start" },
      // { text: "ข้อมูล", value: "action", align: "center" },
    ],
    items: [],
    drug_name: "",
    itemdrug: [
      {
        text: "ทั้งหมด",
        value: "",
      },
      {
        text: "ยาบ้า",
        value: "ยาบ้า",
      },
      {
        text: "ไอซ์",
        value: "ไอซ์",
      },
      {
        text: "ยาอี",
        value: "ยาอี",
      },
      {
        text: "กัญชา",
        value: "กัญชา",
      },
      {
        text: "ยาเค",
        value: "ยาเค",
      },
      {
        text: "กระท่อม",
        value: "กระท่อม",
      },
    ],
    seriesUsers: [
      {
        name: "ยาบ้า",
        data: [],
      },
      {
        name: "ยาไอซ์",
        data: [],
      },
      {
        name: "ยาอี",
        data: [],
      },
      {
        name: "กัญชา",
        data: [],
      },
      {
        name: "ยาเค",
        data: [],
      },
      {
        name: "กระท่อม",
        data: [],
      },
    ],
    chartOptions: {
      chart: {
        height: 350,
        type: "bar",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "จำนวน",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          // "ธค",
          "มกราคม",
        ],
      },
    },
  }),

  created() {
    this.user = JSON.parse(Decode.decode(localStorage.getItem("gscmAdmin")));
    this.getm_group();
    this.getAllData();
  },
  methods: {
    async getm_group() {
      const m_groupItems = await this.axios.get(
        `${process.env.VUE_APP_API}/orgs`
      );
      this.m_groupItems = m_groupItems.data.data;
      console.log("this.m_groupItems", this.m_groupItems);
    },
    async getm_area(val) {
      console.log("val", val);
      const m_areaItems = await this.axios.get(
        `${process.env.VUE_APP_API}/orgs/findAreaByGroup?group=${this.m_group}`
      );
      this.m_areaItems = m_areaItems.data.data;
      console.log("this.m_areaItems", this.m_areaItems);
    },
    async getm_province(val) {
      console.log("val", val);
      const m_provinceItems = await this.axios.get(
        `${process.env.VUE_APP_API}/orgs/findProvinceByArea?area=${this.m_area}`
      );
      this.m_provinceItems = m_provinceItems.data.data;
      console.log("this.m_provinceItems", this.m_provinceItems);
    },
    clear() {
      this.drug_name = "";
      this.date = new Date("2022-01-01").toISOString().substr(0, 10);
      this.date1 = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.m_area = "";
      this.m_group = "";
      this.m_province = "";
      this.getAllData();
    },
    async getAllData() {
      this.loading = true;
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        // `${process.env.VUE_APP_API}/reportForms/countByDrug?drug_name=${this.drug_name}&fromDate=2022-01-27&toDate=2022-01-27`,
        `${process.env.VUE_APP_API}/reportForms/countByDrug?drug_name=${this.drug_name}&fromDate=${this.date}&toDate=${this.date1}&group=${this.m_group}&area=${this.m_area}&province=${this.m_province}`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllbannerpe", response.data.data);
      this.items = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.items) {
        this.items[i].number = parseInt(i) + 1;
      }

      let userData = [];
      // response.data.data.forEach((user) => {
      //   userData.push(user.count);
      // });
      let drug = this.items.filter(function (el) {
        return el.drug_name == "ยาบ้า";
      });

      if (drug.length == 0) {
        drug.push({ count: 0 });
      }

      console.log(drug);
      let ice = this.items.filter(function (el) {
        return el.drug_name == "ไอซ์";
      });

      if (ice.length == 0) {
        ice.push({ count: 0 });
      }

      let drugE = this.items.filter(function (el) {
        return el.drug_name == "ยาอี";
      });

      if (drugE.length == 0) {
        drugE.push({ count: 0 });
      }

      let weed = this.items.filter(function (el) {
        return el.drug_name == "กัญชา";
      });

      if (weed.length == 0) {
        weed.push({ count: 0 });
      }

      let drugK = this.items.filter(function (el) {
        return el.drug_name == "กัญชา";
      });

      if (drugK.length == 0) {
        drugK.push({ count: 0 });
      }

      let tom = this.items.filter(function (el) {
        return el.drug_name == "กระท่อม";
      });

      if (tom.length == 0) {
        tom.push({ count: 0 });
      }
      this.seriesUsers[0].data = [drug[0].count || 0 * 1];
      this.seriesUsers[1].data = [ice[0].count || 0 * 1];
      this.seriesUsers[2].data = [drugE[0].count || 0 * 1];
      this.seriesUsers[3].data = [weed[0].count || 0 * 1];
      this.seriesUsers[4].data = [drugK[0].count || 0 * 1];
      this.seriesUsers[5].data = [tom[0].count || 0 * 1];

      console.log("this.seriesUsers", this.seriesUsers);
      this.loading = false;
    },
  },
};
</script>
